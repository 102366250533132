import { useIsEnabled } from '@troon/analytics';
import { Show } from 'solid-js';
import NotFound from './_/404';
import type { RouteSectionProps } from '@solidjs/router';

export default function DotCom(props: RouteSectionProps) {
	const isEnabled = useIsEnabled('dot-com-takeover');

	return (
		<Show when={isEnabled} fallback={<NotFound />}>
			{props.children}
		</Show>
	);
}
